import React from "react"
import Layout from "../components/Layout"
import Image from "../components/Image"
import { Container } from "../components/Sections"

const IndexPageTemplate = ({
  title,
  description,
  body,
  image1,
  image2,
  image3
}) => {
  return (
    <Layout>
      <Container>
        <div className="grid md:grid-cols-3 gap-4 mb-12">
          <div className="aspect-square bg-gray-100 flex">
          <Image
            image={image1}
            alt=""
            placeholder="dominantColor"
            imgClassName="object-cover object-center w-full"
          />
          </div>
          <div className="aspect-square bg-gray-100 flex">
          <Image
            image={image2}
            alt=""
            placeholder="dominantColor"
            imgClassName="object-cover object-center w-full"
          />
          </div>
          <div className="aspect-square bg-gray-100 flex">
          <Image
            image={image3}
            alt=""
            placeholder="dominantColor"
            imgClassName="object-cover object-center w-full"
          />
          </div>
        </div>
        <div>
        {body &&
           <div className={'prose prose-lg text-center mx-auto max-w-none'} dangerouslySetInnerHTML={{ __html: body }} />
         }
        </div>
      </Container>
    </Layout>
  )
}

export default IndexPageTemplate
