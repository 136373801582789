import React from "react"
import { graphql } from "gatsby"

import IndexPageTemplate from "./IndexPageTemplate"

const IndexPage = ({ data }) => {
  const { frontmatter: fm, html } = data.markdownRemark

  return (
    <>
      <IndexPageTemplate
        title={fm.title}
        description={fm.description}
        body={html}
        image1={fm.image1}
        image2={fm.image2}
        image3={fm.image3}
      />
    </>
  )
}

export default IndexPage


export const Head = ({ data }) => (
    <>
    <title>{data.markdownRemark.frontmatter.title}</title>
    <meta name="description" content={data.markdownRemark.frontmatter.description} />
    </>
)

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        image1 {
          childImageSharp {
            gatsbyImageData(
              width: 500
              height: 500
              formats:[AUTO, WEBP, AVIF]
              placeholder: DOMINANT_COLOR
              transformOptions:{
                fit: COVER
              }
            )
          }
        }
        image2 {
          childImageSharp {
            gatsbyImageData(
              width: 500
              height: 500
              formats:[AUTO, WEBP, AVIF]
              placeholder: DOMINANT_COLOR
              transformOptions:{
                fit: COVER
              }
            )
          }
        }
        image3 {
          childImageSharp {
            gatsbyImageData(
              width: 500
              height: 500
              formats:[AUTO, WEBP, AVIF]
              placeholder: DOMINANT_COLOR
              transformOptions:{
                fit: COVER
              }
            )
          }
        }
      }
    }
  }
`
